(window as any).__Zone_disable_Error = true; // Zone will not patch Error
(window as any).__Zone_disable_on_property = true; // Zone will not patch onProperty such as button.onclick
(window as any).__Zone_disable_geolocation = true; // Zone will not patch geolocation API
(window as any).__Zone_disable_toString = true; // Zone will not patch Function.prototype.toString
(window as any).__Zone_disable_blocking = true; // Zone will not patch alert/prompt/confirm
(window as any).__Zone_disable_PromiseRejectionEvent = true; // Zone will not patch PromiseRejectionEventHandler
(window as any).__Zone_disable_EventEmitter = true;
(window as any).__Zone_disable_XHR = true;
(window as any).__Zone_ignore_on_properties = [
  {
    target: XMLHttpRequest.prototype,
    ignoreProperties: ['load'],
  },
];
(window as any).__Zone_disable_timers = true;
(window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
(window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
(window as any).__zone_symbol__BLACK_LISTED_EVENTS = [
  'scroll',
  'mouseenter',
  'mousedown',
  'mouseleave',
  'mousemove',
  'mouseover',
  'mouseout',
  'mousewheel',
  'pointermove',
  'pointerup',
  'focus',
]; // disable patch specified eventNames

/* black list scroll event handler for onProp */
const targets = [window, Document.prototype, HTMLBodyElement.prototype, HTMLElement.prototype];
(window as any).__Zone_ignore_on_properties = [];
targets.forEach((target) => {
  (window as any).__Zone_ignore_on_properties.push({
    target,
    ignoreProperties: [
      'scroll',
      'mouseenter',
      'mousedown',
      'mouseleave',
      'mousemove',
      'mouseover',
      'mouseout',
      'mousewheel',
      'pointermove',
      'pointerup',
      'focus',
    ],
  });
});
// *
// *  in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
// *  with the following flag, it will bypass `zone.js` patch for IE/Edge
// *
// *  (window as any).__Zone_enable_cross_context_check = true;
